<template>
  <Metrica />
</template>

<script>
import Metrica from "@/components/gestao/Metrica.vue";

export default {
  components: {
    Metrica
  }
};
</script>
